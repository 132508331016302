import React from 'react'
import './PrivacyPolicy.css';



const PrivacyPolicy = () => {
  return (
    <div className='policyWrapper page-wrap'>
        <div className='container'>
        <h2>Privacy Policy:</h2>
        <p>Ecos Offset Private Limited ("Ecos Offset," "we," "our," or "us") is committed to protecting the privacy and security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide to us through our website and any related services.</p>
        <p>Information We Collect: We may collect personal information such as your name, email address, phone number, and company name when you interact with our website, subscribe to our newsletter, or use our services. We may also collect non-personal information such as IP addresses, browser type, and device identifiers.</p>
        <p>How We Use Your Information: We use the information we collect to provide and improve our services, communicate with you, personalize your experience, and comply with legal obligations. We may also use your information for marketing purposes, such as sending promotional emails or newsletters.</p>
        <p>Information Sharing: We may share your personal information with third-party service providers, business partners, or affiliates who assist us in delivering our services or conducting our business operations. We may also disclose your information in response to legal requests or to protect our rights, property, or safety.</p>
        <p>Data Security: We implement reasonable security measures to protect your personal information from unauthorized access, use, or disclosure. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
        <p>Your Choices: You have the right to access, update, or delete your personal information at any time. You may also opt out of receiving marketing communications from us by following the unsubscribe instructions included in our emails. However, please note that certain communications, such as transactional emails, are necessary for the provision of our services.</p>
        <p>Changes to This Policy: We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated policy on our website or sending you a notification.</p>
        <p>Contact Us: If you have any questions or concerns about our Privacy Policy or our handling of your personal information, please contact us at [email address].</p>
        <h3>Terms of Service:</h3>
        <p>Welcome to the Ecos Offset Private Limited website ("Site"). By accessing or using the Site, you agree to comply with and be bound by these Terms of Service ("Terms"). If you do not agree with these Terms, you may not access or use the Site.</p>
        <p>Use of the Site: You may use the Site for lawful purposes only. You agree not to use the Site in any way that violates applicable laws or regulations or infringes on the rights of others. You further agree not to engage in any conduct that could damage, disable, or impair the Site or interfere with any other party's use of the Site.</p>
        <p>Intellectual Property: The content, logos, trademarks, and other intellectual property displayed on the Site are owned by Ecos Offset or its licensors and are protected by copyright and other intellectual property laws. You may not use, reproduce, or distribute any content from the Site without our prior written consent.</p>
        <p>Third-Party Links: The Site may contain links to third-party websites or resources. These links are provided for your convenience only and do not imply endorsement or approval by Ecos Offset. We are not responsible for the content or accuracy of third-party websites, and your use of such websites is at your own risk.</p>
        <p>Disclaimer: The information provided on the Site is for general informational purposes only. We make no representations or warranties of any kind, express or implied, about the accuracy, reliability, or completeness of the information. Your use of the Site is at your own risk.</p>
        <p>Limitation of Liability: In no event shall Ecos Offset or its affiliates be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with your use of the Site or the content therein.</p>
        <p>Governing Law: These Terms shall be governed by and construed in accordance with the laws of [insert jurisdiction]. Any dispute arising out of or relating to these Terms shall be subject to the exclusive jurisdiction of the courts located in [insert jurisdiction].</p>
        <p>Changes to These Terms: We reserve the right to update or modify these Terms at any time without prior notice. Any changes will be effective immediately upon posting on the Site. Your continued use of the Site following the posting of changes constitutes acceptance of those changes.</p>
        <p>If you have any questions or concerns about these Terms or our Privacy Policy, please contact us at [email address]. Thank you for visiting our Site.</p>
        </div>
    </div>
  )
}

export default PrivacyPolicy
