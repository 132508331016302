import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneVolume, faClock, faLocationDot, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';


function Headers() {
    const [menuOpen, setMenuOpen] = useState(0);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }


    return (
        <header className="header-style-2">
            <div className="bg-header-top">
                <div className="container">
                    <div className="rows">
                        <div className="header-top">
                            <ul className="h-contact">
                                <li><FontAwesomeIcon icon={faClock} /> Time : Monday-Saturday : 9:00am-6:00pm</li>
                                <li><FontAwesomeIcon icon={faPhoneVolume} />  Phone : +91 79797 17630</li>
                                <li><FontAwesomeIcon icon={faEnvelope} />  Email : info@ecosoffset.com</li>
                                <li><FontAwesomeIcon icon={faLocationDot} /> Address : Rachi Jharkhand</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-main-menu menu-scroll">
                <div className="container">
                    <div className="row">
                        <div className="main-menu">
                            <div className="main-menu-bottom">
                                <div className="navbar-header">
                                    <a className="navbar-brand" href="/">
                                        <img src="assets/images/file.png" alt="logo" className="img-responsive" />
                                    </a>

                                    <button className="navbar-toggler" type="button" onClick={toggleMenu}>
                                    <span className="navbar-toggler-icon"></span>
                                    </button>
                                </div>
                                <div className={menuOpen ? "main-menu-area open" : "main-menu-area"}>
                                    <div className="navbar-collapse">
                                        <ul>
                                            <li className="menu-item-has">
                                                <Link to="/">home</Link>
                                            </li>
                                            <li>
                                                <Link to="/about-us">About</Link>
                                            </li>
                                            <li className="menu-item-has-children">
                                                <Link href="/">pages</Link>
                                                <ul className="sub-menu">
                                                    <li className="menu-item-has-children">
                                                        <Link href="/"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Our Team</Link>
                                                        <ul className="sub-sub-menu">
                                                            <li><Link href="/team"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Team</Link></li>
                                                        </ul>
                                                    </li>
                                                    <li className="menu-item-has-children">
                                                        <Link href="/"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Our project</Link>
                                                        <ul className="sub-sub-menu">
                                                            <li><a href="/project"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Projects</a></li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <Link to="/portfolio">
                                                            portfolio
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="menu-item-has">
                                                <Link to="/services">Services</Link>
                                            </li>
                                            <li className="menu-item-has">
                                                <Link to="/gallery">Gallery</Link>
                                            </li>
                                            <li>
                                                <Link to="/contact-us">contacts</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </header >
    )
}

export default Headers;