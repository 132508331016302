import { useState } from "react";
import './Service.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function Services() {
    const [activeTab, setActiveTab] = useState("tab1");

    return (
        <div className='services-page page-wrap'>
            <div className='container'>
                <div className="row">
                    <div className="col-md-4 mobOrder">
                        <div className="asideListing">
                            <aside>
                                <ul>
                                    <li className={activeTab === "tab1" ? "tab active" : "tab"}
                onClick={() => setActiveTab("tab1")}>Wildlife Conservation and Management Plan <FontAwesomeIcon icon={faArrowRight} size="1x" /> </li>
                                    <li className={activeTab === "tab2" ? "tab active" : "tab"}
                onClick={() => setActiveTab("tab2")}>Soil and Moisture Conservation Plan and Catchment Treatment Plan <FontAwesomeIcon icon={faArrowRight} size="1x" /></li>
                                    <li className={activeTab === "tab3" ? "tab active" : "tab"}
                onClick={() => setActiveTab("tab3")}>Tree Enumeration Services <FontAwesomeIcon icon={faArrowRight} size="1x" /></li>
                                    <li className={activeTab === "tab4" ? "tab active" : "tab"}
                onClick={() => setActiveTab("tab4")}>Identification and Selection of Compensatory Afforestation Plan <FontAwesomeIcon icon={faArrowRight} size="1x" /></li>
                                    <li className={activeTab === "tab5" ? "tab active" : "tab"}
                onClick={() => setActiveTab("tab5")}>Compliance of Forest, Wildlife, and Environmental Clearance Conditions <FontAwesomeIcon icon={faArrowRight} size="1x" /></li>
                                    <li className={activeTab === "tab6" ? "tab active" : "tab"}
                onClick={() => setActiveTab("tab6")}>Forest Working Plan, Master Plan, and Micro Plan Services <FontAwesomeIcon icon={faArrowRight} size="1x" /></li>
                                    <li className={activeTab === "tab7" ? "tab active" : "tab"}
                onClick={() => setActiveTab("tab7")}>Forest Capacity Building and Training Services <FontAwesomeIcon icon={faArrowRight} size="1x" /></li>
                                    <li className={activeTab === "tab8" ? "tab active" : "tab"}
                onClick={() => setActiveTab("tab8")}>Forest and Wildlife Clearance Services <FontAwesomeIcon icon={faArrowRight} size="1x" /></li>
                                    <li className={activeTab === "tab9" ? "tab active" : "tab"}
                onClick={() => setActiveTab("tab9")}>Ecological Services <FontAwesomeIcon icon={faArrowRight} size="1x" /></li>
                                    <li className={activeTab === "tab10" ? "tab active" : "tab"}
                onClick={() => setActiveTab("tab10")}>Biodiversity Offset Program <FontAwesomeIcon icon={faArrowRight} size="1x" /></li>
                                    <li className={activeTab === "tab11" ? "tab active" : "tab"}
                onClick={() => setActiveTab("tab11")}>Socio-economic Development Strategies <FontAwesomeIcon icon={faArrowRight} size="1x" /></li>
                                </ul>
                            </aside>
                        </div>
                    </div>
                    <div className="col-md-8">
                        {activeTab === "tab1" && <div className="sectionWrap">
                            <p><strong>Wildlife Conservation and Management Plan</strong></p>
                            <p>Welcome to Ecos Offset, your trusted partner in crafting bespoke wildlife conservation and management plans that ensure the protection of vulnerable species and habitats. At Ecos Offset, we are dedicated to promoting harmonious coexistence between human development and wildlife conservation, and our specialized expertise enables us to navigate the complexities of environmental regulations while achieving sustainable goals.</p>
                            <p><strong>Why Choose Us?</strong></p>
                            <ul className='arrowStyle'>
                                <li><strong>Tailored Solutions:</strong> We understand that every project is unique, which is why we specialize in crafting customized wildlife conservation and management plans tailored to meet the specific needs and objectives of our clients. Our solutions are designed to address the challenges of each project site while ensuring the protection of biodiversity.</li>
                                <li><strong>Expertise and Experience</strong>: Our team comprises experts in wildlife conservation, environmental science, and regulatory compliance. With years of experience in the field, we have the knowledge and skills to navigate the intricacies of forest, wildlife, and environmental clearances, ensuring that our plans meet all statutory requirements.</li>
                                <li><strong>Comprehensive Services</strong>: From conducting comprehensive biodiversity surveys to engaging with stakeholders and facilitating regulatory approval processes, we offer a wide range of services to support every stage of your project. Our holistic approach ensures that all aspects of wildlife conservation and management are addressed effectively.</li>
                                <li><strong>Attention to Detail:</strong> We pay close attention to every detail of our wildlife conservation and management plans, ensuring that they are meticulously crafted to achieve optimal results. Our thorough assessments, stakeholder consultations, and identification of wildlife corridors ensure that all key aspects are considered in the planning process.</li>
                                <li><strong>Commitment to Sustainability:</strong> At Ecos Offset, we are committed to promoting sustainable development practices that protect the environment and support local communities. Our wildlife conservation and management plans not only safeguard biodiversity but also contribute to the long-term sustainability of ecosystems and the well-being of future generations.</li>
                            </ul>
                            <p>Choose Ecos Offset as your partner in wildlife conservation and management, and let us help you achieve your sustainability goals while preserving the natural beauty and biodiversity of our planet.</p>
                        </div>}
                       {activeTab === "tab2" && <div className="sectionWrap">
                            <p><strong>Soil and Moisture Conservation Plan and Catchment Treatment Plan</strong></p>
                            <p>Welcome to Ecos Offset Private Limited, where we specialize in crafting customized Soil and Moisture Conservation Plans and Catchment Treatment Plans tailored to your specific needs and project objectives. Our plans are meticulously designed to enhance soil health, prevent erosion, and promote sustainable water management practices within project areas.</p>
                            <p><strong>Why Choose Us?</strong></p>
                            <ul className='arrowStyle'>
                                <li><strong>Expert Assessment:</strong> Our team conducts thorough assessments of soil characteristics, moisture levels, and erosion risks using state-of-the-art techniques and technologies. This ensures that our plans are based on accurate data and tailored to the unique conditions of your project site.</li>
                                <li><strong>Customized Strategies:</strong> Drawing on our expertise and knowledge, we develop customized conservation strategies aimed at preserving soil health and promoting sustainable water management practices. From contour plowing to water harvesting structures, our plans are designed to address your project's specific needs while considering local environmental factors.</li>
                                <li><strong>Comprehensive Implementation:</strong> We work closely with you to ensure that every aspect of the plan is carefully considered and executed to achieve optimal results. Our detailed implementation plans outline specific conservation measures, timelines, and resource requirements for effective project execution.</li>
                                <li><strong>Catchment Treatment Expertise</strong>: In addition to soil and moisture conservation, we specialize in catchment treatment, restoring and enhancing the natural hydrological functions of catchment areas. Our solutions include reforestation, riparian zone restoration, and erosion control measures to ensure sustainable water resource management.</li>
                                <li><strong>Regulatory Compliance:</strong> We adhere to all relevant scopes and guidelines during the preparation of Soil and Moisture Conservation Plans and Catchment Treatment Plans, ensuring compliance with the latest amendments and directives from State government and MoEF&amp;CC.</li>
                                <li><strong>Facilitated Approval Process:</strong> Our team facilitates the approval process by liaising with government authorities at different levels, ultimately seeking approval from the competent authority. We ensure that all regulatory requirements are met for a seamless approval process.</li>
                            </ul>
                            <p>At Ecos Offset, we are committed to promoting sustainable land and water management practices that enhance ecosystem resilience and contribute to long-term environmental sustainability. Our Soil and Moisture Conservation Plans and Catchment Treatment Plans serve as blueprints for preserving soil health, conserving water resources, and mitigating erosion, ensuring the sustainable use of natural resources for future generations.</p>
                        </div>}
                        {activeTab === "tab3" && <div className="sectionWrap">
                            <p><strong>Tree Enumeration Services</strong></p>
                            <p>At Ecos Offset, we understand the importance of trees in maintaining ecological balance and promoting sustainable development. Our Tree Enumeration Services are designed to provide accurate and comprehensive data on tree populations, facilitating informed decision-making and effective management of forest resources.</p>
                            <p><strong>Why Choose Us?</strong></p>
                            <p><strong>Expertise:</strong> Our team comprises skilled professionals with extensive experience in tree enumeration and forest management. With a deep understanding of forestry principles and techniques, we ensure accurate and reliable data collection.</p>
                            <p><strong>Cutting-edge Technology</strong>: Leveraging advanced technologies such as GIS (Geographic Information System) and GPS (Global Positioning System), we employ efficient data collection methods to map and quantify tree populations with precision.</p>
                            <p><strong>Comprehensive Assessment:</strong> We conduct thorough surveys to assess tree species, size, distribution, and health status within specified areas. Our detailed reports provide valuable insights into forest ecosystems, aiding in conservation and management efforts.</p>
                            <p><strong>Customized Solutions</strong>: Whether it's for ecological studies, urban planning, or resource management, we tailor our tree enumeration services to meet the specific needs and objectives of our clients. Our flexible approach ensures that the data collected is relevant and actionable.</p>
                            <p><strong>Environmental Stewardship:</strong> At Ecos Offset, we are committed to promoting environmental stewardship and sustainability. By accurately assessing tree populations and monitoring changes over time, we contribute to the preservation and protection of forests for future generations.</p>
                            <p><strong>Our Services Include:</strong></p>
                            <ol className="numericStyle">
                                <li>Tree Species Identification</li>
                                <li>Tree Size Measurement (Diameter at Breast Height, Height)</li>
                                <li>Tree Density and Distribution Mapping</li>
                                <li>Health and Condition Assessment</li>
                                <li>Carbon Stock Estimation</li>
                                <li>Urban Tree Inventories</li>
                                <li>Ecological Impact Studies</li>
                                <li>Forest Management Planning</li>
                            </ol>
                            <p><strong>Partner with Us:</strong></p>
                            <p>Join hands with Ecos Offset for reliable and professional Tree Enumeration Services. Together, let's work towards conserving our precious forests and ensuring a greener, more sustainable future. Contact us today to discuss your project requirements and explore how we can assist you in achieving your goals.</p></div>}
                        {activeTab === "tab4" && <div className="sectionWrap">
                            <p><strong>Identification and Selection of Compensatory Afforestation Plan:</strong></p>
                            <p>At Ecos Offset Private Limited, we specialize in the identification and selection of Compensatory Afforestation Plans tailored to meet the specific needs and objectives of our clients. Our expertise in this area allows us to navigate the complexities of compensatory afforestation requirements and ensure compliance with regulatory standards.</p>
                            <p><strong>Why Choose Us?</strong></p>
                            <p><strong>Expertise</strong>: With years of experience in forestry and environmental consulting, our team possesses the expertise needed to identify and select appropriate Compensatory Afforestation Plans. We stay updated on the latest regulations and guidelines to ensure compliance with statutory requirements.</p>
                            <p><strong>Comprehensive Assessment:</strong> We conduct thorough assessments of project sites to determine the most suitable afforestation strategies based on factors such as soil conditions, climate, and ecological considerations. Our comprehensive approach ensures that the selected plans are environmentally sound and sustainable.</p>
                            <p><strong>Stakeholder Engagement:</strong> We engage with stakeholders, including government agencies, local communities, and environmental experts, to gather input and ensure that the selected Compensatory Afforestation Plans align with their needs and priorities. By fostering collaboration, we facilitate the implementation of effective afforestation initiatives.</p>
                            <p><strong>Customized Solutions:</strong> Our approach to identifying and selecting Compensatory Afforestation Plans is tailored to each project's unique requirements. Whether it's reforestation, agroforestry, or afforestation of degraded lands, we develop customized solutions that address the specific goals and challenges of our clients.</p>
                            <p><strong>Regulatory Compliance:</strong> We ensure that all selected Compensatory Afforestation Plans adhere to regulatory standards and guidelines set forth by government authorities. Our rigorous approach to compliance ensures that our clients' projects proceed smoothly and in accordance with legal requirements.</p>
                            <p><strong>Our Approach</strong>:</p>
                            <ol className="numericStyle">
                                <li>Conduct site assessments to identify suitable areas for afforestation.</li>
                                <li>Evaluate various afforestation options based on ecological, social, and economic factors.</li>
                                <li>Engage stakeholders to gather input and ensure alignment with project goals.</li>
                                <li>Develop customized Compensatory Afforestation Plans tailored to client needs.</li>
                                <li>Facilitate the implementation and monitoring of selected plans to ensure their success.</li>
                            </ol>
                            <p><strong>Partner with Us:</strong></p>
                            <p>Choose Ecos Offset for expert assistance in the identification and selection of Compensatory Afforestation Plans. Let us help you meet your afforestation goals while ensuring compliance with regulatory requirements and promoting environmental sustainability. Contact us today to discuss your project needs and discover how we can support your efforts.</p>
                        </div>}
                        {activeTab === "tab5" && <div className="sectionWrap">
                            <p><strong>Compliance of Forest, Wildlife, and Environmental Clearance Conditions:</strong></p>
                            <p>At Ecos Offsets Private Limited, we specialize in ensuring the compliance of forest, wildlife, and environmental clearance conditions, helping our clients navigate the regulatory landscape and meet their obligations effectively. Our comprehensive approach to compliance management encompasses a range of services tailored to address the specific requirements of each project.</p>
                            <p><strong>Why Choose Us?</strong></p>
                            <p><strong>Expertise</strong>: With a team of seasoned professionals well-versed in forestry, wildlife conservation, and environmental regulations, we possess the expertise needed to ensure compliance with clearance conditions. Our knowledge of the regulatory framework enables us to guide our clients through the compliance process seamlessly.</p>
                            <p><strong>Thorough Assessment:</strong> We conduct thorough assessments of project sites to identify applicable forest, wildlife, and environmental clearance conditions. By analyzing the specific requirements set forth by regulatory authorities, we develop a comprehensive understanding of the compliance obligations that need to be met.</p>
                            <p><strong>Customized Solutions:</strong> Our approach to compliance management is tailored to the unique needs and objectives of each project. We work closely with our clients to develop customized compliance plans that address their specific clearance conditions while minimizing risks and ensuring adherence to regulatory standards.</p>
                            <p><strong>Stakeholder Engagement</strong>: We engage with relevant stakeholders, including government agencies, local communities, and environmental experts, to facilitate compliance with clearance conditions. By fostering collaboration and communication, we ensure that all parties are informed and involved in the compliance process.</p>
                            <p><strong>Continuous Monitoring:</strong> We provide ongoing monitoring and support to ensure that our clients remain in compliance with forest, wildlife, and environmental clearance conditions throughout the project lifecycle. Our proactive approach helps identify and address any compliance issues promptly, minimizing potential delays or penalties.</p>
                            <p><strong>Our Services:</strong></p>
                            <ol className="numericStyle">
                                <li>Identification of applicable forest, wildlife, and environmental clearance conditions.</li>
                                <li>Development of customized compliance plans tailored to project requirements.</li>
                                <li>Stakeholder engagement and consultation to ensure alignment with clearance conditions.</li>
                                <li>Ongoing monitoring and support to ensure continuous compliance throughout the project lifecycle.</li>
                                <li>Assistance with documentation and reporting to regulatory authorities.</li>
                            </ol>
                            <p><strong>Partner with Us:</strong></p>
                            <p>Choose Ecos Offset for expert assistance in the compliance of forest, wildlife, and environmental clearance conditions. Let us help you navigate the regulatory landscape and meet your obligations effectively, ensuring the successful implementation of your projects while minimizing risks. Contact us today to discuss your compliance needs and discover how we can support your efforts.</p>
                        </div>}
                        {activeTab === "tab6" && <div className="sectionWrap">
                            <p><strong>Forest Working Plan, Master Plan, and Micro Plan Services:</strong></p>
                            <p>Ecos Offset Private Limited offers comprehensive services for the development and implementation of Forest Working Plans, Master Plans, and Micro Plans, tailored to meet the specific needs and objectives of our clients. Our expertise in forestry management and planning ensures the sustainable utilization and conservation of forest resources while promoting ecological balance and socio-economic development.</p>
                            <p><strong>Why Choose Us?</strong></p>
                            <p><strong>Expertise:</strong> With a team of experienced forestry professionals and consultants, we possess the expertise needed to develop comprehensive forest management plans. Our knowledge of forestry principles, ecological processes, and regulatory requirements enables us to deliver effective solutions tailored to client needs.</p>
                            <p><strong>Integrated Approach: </strong>We adopt an integrated approach to forest planning, encompassing Forest Working Plans, Master Plans, and Micro Plans to address various aspects of forest management. This holistic approach ensures that all components of forest management, from timber harvesting to biodiversity conservation, are considered in the planning process.</p>
                            <p><strong>Customized Solutions:</strong> We work closely with our clients to understand their specific goals and objectives, tailoring our planning services to meet their unique needs. Whether it's optimizing timber production, conserving biodiversity, or enhancing ecosystem services, we develop customized solutions that align with client priorities.</p>
                            <p><strong>Stakeholder Engagement</strong>: We recognize the importance of stakeholder involvement in forest planning processes. Through consultation with local communities, government agencies, and other stakeholders, we ensure that our plans reflect diverse perspectives and priorities, fostering greater buy-in and support for implementation.</p>
                            <p><strong>Regulatory Compliance:</strong> Our plans are developed in compliance with relevant regulatory requirements and guidelines, ensuring adherence to legal and environmental standards. We stay updated on the latest regulations and best practices to ensure that our clients remain in compliance with regulatory requirements.</p>
                            <p><strong>Our Services:</strong></p>
                            <ol className="numericStyle">
                                <li>Development of Forest Working Plans, outlining long-term strategies for sustainable forest management.</li>
                                <li>Preparation of Master Plans, providing a framework for the overall development and management of forest resources.</li>
                                <li>Design and implementation of Micro Plans, focusing on specific areas or activities within forest management units.</li>
                                <li>Stakeholder engagement and consultation to ensure alignment with community needs and regulatory requirements.</li>
                                <li>Monitoring and evaluation of plan implementation, with adjustments made as needed to achieve desired outcomes.</li>
                            </ol>
                            <p><strong>Partner with Us:</strong></p>
                            <p>Choose Ecos Offset for expert assistance in Forest Working Plan, Master Plan, and Micro Plan development. Let us help you optimize forest management practices, conserve biodiversity, and promote sustainable development. Contact us today to discuss your forest planning needs and explore how we can support your goals.</p>
                        </div>}
                        {activeTab === "tab7" && <div className="sectionWrap">
                            <p><strong>Forest Capacity Building and Training Services:</strong></p>
                            <p>Ecos Offset Private Limited offers comprehensive capacity building and training services tailored to enhance the knowledge and skills of individuals and organizations involved in forest management and conservation. Our training programs are designed to empower participants with the tools, techniques, and best practices needed to address the challenges of sustainable forest management effectively.</p>
                            <p><strong>Why Choose Us?</strong></p>
                            <p><strong>Expertise:</strong> Our team consists of seasoned professionals with extensive experience in forestry management, conservation, and sustainable development. With a deep understanding of forestry principles and practices, we deliver high-quality training programs that meet industry standards and best practices.</p>
                            <p><strong>Customized Training Solutions</strong>: We recognize that every organization and individual has unique training needs. That's why we offer customized training solutions tailored to address specific requirements and objectives. Whether it's capacity building for forest managers, community-based forest management training, or specialized workshops on forest conservation, we design programs that align with client goals.</p>
                            <p><strong>Interactive Learning Approach:</strong> Our training programs are designed to be engaging, interactive, and participatory, ensuring that participants are actively involved in the learning process. Through a combination of lectures, group discussions, case studies, and hands-on exercises, we create dynamic learning environments that promote knowledge retention and skill development.</p>
                            <p><strong>Comprehensive Curriculum:</strong> Our training curriculum covers a wide range of topics relevant to forest management and conservation, including forest ecology, biodiversity conservation, sustainable harvesting practices, forest monitoring and assessment, community engagement, and policy and governance. Participants gain a comprehensive understanding of key concepts and principles essential for effective forest management.</p>
                            <p><strong>Capacity Building for Sustainable Development:</strong> By investing in capacity building and training, organizations and individuals can strengthen their capabilities to promote sustainable forest management practices, conserve biodiversity, and enhance livelihoods. Our training programs empower participants to become effective stewards of forest resources and champions of environmental sustainability.</p>
                            <p><strong>Our Training Services Include:</strong></p>
                            <ol className="numericStyle">
                                <li>Capacity building workshops for forest managers, government officials, NGOs, and community groups.</li>
                                <li>Training programs on forest ecology, biodiversity conservation, sustainable harvesting techniques, and forest monitoring and assessment.</li>
                                <li>Workshops on community-based forest management, participatory approaches, and stakeholder engagement.</li>
                                <li>Specialized training modules on forest policy and governance, climate change adaptation, and sustainable development.</li>
                            </ol>
                            <p><strong>Partner with Us:</strong></p>
                            <p>Choose Ecos Offset for expert forest capacity building and training services. Let us help you develop the knowledge and skills needed to achieve your forest management and conservation goals. Contact us today to discuss your training needs and explore how we can support your capacity building efforts.</p>
                        </div>}
                        {activeTab === "tab8" && <div className="sectionWrap">
                            <p><strong>Forest and Wildlife Clearance Services:</strong></p>
                            <p>Ecos Offset Private Limited offers comprehensive services for obtaining forest and wildlife clearances, essential for projects involving land development, infrastructure construction, and natural resource utilization in forested areas. Our expertise in navigating the clearance process ensures timely approvals while adhering to regulatory requirements and minimizing environmental impacts.</p>
                            <p><strong>Why Choose Us?</strong></p>
                            <p><strong>Expertise:</strong> With a team of experienced professionals well-versed in forestry, wildlife conservation, and environmental regulations, we possess the expertise needed to facilitate forest and wildlife clearances efficiently. Our knowledge of the clearance process enables us to guide clients through the complexities of regulatory compliance.</p>
                            <p><strong>Regulatory Compliance:</strong> We stay updated on the latest forest and wildlife clearance regulations and guidelines, ensuring that our clients remain in compliance with statutory requirements. From conducting environmental impact assessments to preparing clearance applications, we ensure that all necessary steps are taken to obtain approvals.</p>
                            <p><strong>Stakeholder Engagement:</strong> We engage with relevant stakeholders, including government agencies, local communities, and environmental experts, to gather input and address concerns during the clearance process. By fostering collaboration and communication, we facilitate consensus-building and mitigate potential conflicts.</p>
                            <p><strong>Comprehensive Assessment:</strong> We conduct thorough assessments of project sites to evaluate potential impacts on forest ecosystems and wildlife habitats. Our assessments include habitat surveys, biodiversity assessments, and ecological monitoring to inform clearance applications and mitigation measures.</p>
                            <p><strong>Timely Approvals:</strong> Our streamlined approach to clearance facilitation and documentation ensures timely approvals, minimizing project delays and associated costs. We work proactively to address any issues or concerns raised by regulatory authorities, facilitating a smooth clearance process from start to finish.</p>
                            <p><strong>Our Services Include:</strong></p>
                            <ol className="numericStyle">
                                <li>Environmental Impact Assessments (EIAs) and Ecological Surveys</li>
                                <li>Preparation and submission of forest and wildlife clearance applications</li>
                                <li>Stakeholder consultation and engagement</li>
                                <li>Development of mitigation and conservation plans</li>
                                <li>Monitoring and compliance reporting</li>
                            </ol>
                            <p><strong>Partner with Us:</strong></p>
                            <p>Choose Ecos Offset for expert assistance in obtaining forest and wildlife clearances. Let us help you navigate the regulatory landscape and obtain the necessary approvals for your projects while minimizing environmental impacts. Contact us today to discuss your clearance needs and discover how we can support your efforts.</p>
                        </div>}
                        {activeTab === "tab9" && <div className="sectionWrap">
                            <p><strong>Ecological Services:</strong></p>
                            <p>Ecos Offset Private Limited offers a range of ecological services aimed at promoting the conservation and sustainable management of ecosystems. Our expertise in ecology, biodiversity conservation, and ecosystem restoration allows us to provide tailored solutions to address environmental challenges and promote ecosystem resilience.</p>
                            <p><strong>Why Choose Us?</strong></p>
                            <p><strong>Comprehensive Approach:</strong> We take a holistic approach to ecological services, considering the interconnectedness of ecosystems and the broader landscape. Our services encompass a wide range of activities, including ecological assessments, habitat restoration, biodiversity monitoring, and ecosystem management planning.</p>
                            <p><strong>Expertise:</strong> Our team consists of ecologists, conservation biologists, and environmental scientists with extensive experience in ecosystem management and restoration. With a deep understanding of ecological processes and principles, we deliver high-quality services that meet the needs of our clients and promote environmental sustainability.</p>
                            <p><strong>Customized Solutions:</strong> We recognize that every ecosystem is unique, with its own set of challenges and opportunities. That's why we offer customized solutions tailored to the specific needs and objectives of each project. Whether it's restoring degraded habitats, enhancing biodiversity, or mitigating environmental impacts, we develop strategies that align with client goals.</p>
                            <p><strong>Stakeholder Engagement:</strong> We believe in the importance of engaging stakeholders, including local communities, government agencies, and NGOs, in the planning and implementation of ecological services. By fostering collaboration and communication, we ensure that our projects reflect diverse perspectives and priorities, leading to more effective outcomes.</p>
                            <p><strong>Sustainability:</strong> Our ecological services are designed to promote long-term sustainability, ensuring that ecosystems remain healthy and resilient for future generations. Through careful planning, monitoring, and adaptive management, we strive to achieve lasting environmental benefits that benefit both people and nature.</p>
                            <p><strong>Our Ecological Services Include</strong>:</p>
                            <ol className="numericStyle">
                                <li>Ecological assessments and surveys</li>
                                <li>Habitat restoration and enhancement</li>
                                <li>Biodiversity monitoring and conservation</li>
                                <li>Ecosystem management planning</li>
                                <li>Environmental impact assessments</li>
                                <li>Wetland delineation and restoration</li>
                                <li>Climate change adaptation and resilience planning</li>
                            </ol>
                            <p><strong>Partner with Us:</strong></p>
                            <p>Choose Ecos Offset for expert ecological services that promote the conservation and sustainable management of ecosystems. Let us help you address environmental challenges and achieve your conservation goals. Contact us today to discuss your ecological service needs and explore how we can support your efforts.</p>
                        </div>}
                        {activeTab === "tab10" && <div className="sectionWrap">
                            <p><strong>Biodiversity Offset Program:</strong></p>
                            <p>Ecos Offset Private Limited offers specialized Biodiversity Offset Programs designed to mitigate the environmental impact of development projects while promoting biodiversity conservation and sustainable land management practices. Our programs aim to achieve no net loss or a net gain of biodiversity by implementing offset measures that compensate for ecological damage caused by development activities.</p>
                            <p><strong>Why Choose Us?</strong></p>
                            <p><strong>Expertise:</strong> Our team comprises biodiversity experts, conservationists, and environmental scientists with extensive experience in designing and implementing Biodiversity Offset Programs. We possess the expertise needed to develop effective offset strategies that achieve measurable conservation outcomes.</p>
                            <p><strong>Customized Solutions:</strong> We understand that every development project has unique biodiversity challenges and mitigation needs. That's why we offer customized offset solutions tailored to the specific requirements and objectives of each project. Whether it's habitat restoration, species conservation, or ecosystem enhancement, we develop offset measures that align with project goals and regulatory requirements.</p>
                            <p><strong>Comprehensive Approach</strong>: Our Biodiversity Offset Programs encompass a range of activities aimed at conserving and restoring biodiversity. From habitat creation and enhancement to species translocation and ecosystem rehabilitation, we employ a variety of offset measures to achieve desired conservation outcomes.</p>
                            <p><strong>Stakeholder Engagement:</strong> We believe in the importance of engaging stakeholders, including project developers, regulatory authorities, local communities, and conservation organizations, in the design and implementation of Biodiversity Offset Programs. By fostering collaboration and consultation, we ensure that our programs reflect diverse perspectives and priorities, leading to greater acceptance and support.</p>
                            <p><strong>Monitoring and Evaluation</strong>: We provide ongoing monitoring and evaluation of offset activities to assess their effectiveness and ensure that conservation goals are being met. Our adaptive management approach allows us to make adjustments as needed to optimize outcomes and maximize biodiversity benefits.</p>
                            <p><strong>Our Biodiversity Offset Program Services Include:</strong></p>
                            <ol className="numericStyle">
                            <li>Biodiversity impact assessments</li>
                            <li>Development of offset strategies and action plans</li>
                            <li>Habitat restoration and creation</li>
                            <li>Species conservation and translocation</li>
                            <li>Ecosystem rehabilitation and enhancement</li>
                            <li>Monitoring and evaluation of offset activities</li>
                            <li>Reporting and compliance documentation</li>
                            </ol>
                            <p><strong>Partner with Us:</strong></p>
                            <p>Choose Ecos Offset for expert Biodiversity Offset Programs that balance development needs with biodiversity conservation goals. Let us help you mitigate the environmental impact of your projects while promoting sustainable land management practices and preserving biodiversity for future generations. Contact us today to discuss your Biodiversity Offset Program needs and explore how we can support your efforts.</p>
                        </div>}
                        {activeTab === "tab11" && <div className="sectionWrap">
                            <p><strong>Socio-economic Development Strategies:</strong></p>
                            <p>Ecos Offset Private Limited offers tailored socio-economic development strategies aimed at fostering sustainable livelihoods, enhancing community well-being, and promoting inclusive growth in project areas. Our strategies integrate social, economic, and environmental considerations to create positive impacts that benefit both communities and ecosystems.</p>
                            <p><strong>Why Choose Us?</strong></p>
                            <p><strong>Holistic Approach:</strong> We take a holistic approach to socio-economic development, recognizing the interconnectedness of social, economic, and environmental factors. Our strategies are designed to address the complex challenges facing communities while promoting environmental sustainability and resilience.</p>
                            <p><strong>Community Engagement:</strong> We believe in the importance of engaging local communities as partners in the development process. Our strategies are developed in consultation with community members, ensuring that they reflect local priorities, values, and aspirations. By fostering meaningful participation and collaboration, we build trust and ownership, leading to more sustainable outcomes.</p>
                            <p><strong>Capacity Building:</strong> We invest in building the capacity of local stakeholders to participate effectively in socio-economic development initiatives. Through training, skills development, and knowledge transfer, we empower individuals and organizations to take an active role in shaping their own futures and building resilient communities.</p>
                            <p><strong>Partnerships:</strong> We leverage partnerships with government agencies, NGOs, businesses, and other stakeholders to maximize the impact of our socio-economic development initiatives. By pooling resources, expertise, and networks, we create synergies and scale up efforts to address shared challenges and achieve common goals.</p>
                            <p><strong>Impact Assessment:</strong> We conduct rigorous impact assessments to evaluate the effectiveness and sustainability of our socio-economic development strategies. By monitoring key indicators and collecting feedback from stakeholders, we continuously improve our interventions and ensure that they deliver meaningful benefits to communities.</p>
                            <p><strong>Our Socio-economic Development Strategies Include:</strong></p>
                            <ol className="numericStyle">
                            <li>Livelihood enhancement programs</li>
                            <li>Skills training and capacity building</li>
                            <li>Income generation activities</li>
                            <li>Infrastructure development</li>
                            <li>Social welfare initiatives</li>
                            <li>Health and education programs</li>
                            <li>Gender mainstreaming and empowerment</li>
                            </ol>
                            <p><strong>Partner with Us</strong>:</p>
                            <p>Choose Ecos Offset for expert socio-economic development strategies that promote inclusive growth and sustainable development. Let us help you create positive impacts that benefit communities, ecosystems, and economies. Contact us today to discuss your socio-economic development needs and explore how we can support your efforts.</p>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;