
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

//import Headers from './components/Headers/Headers';
//import SliderComponent from './components/Sections/SliderSections/SliderComponent';
//import Aboutus from './components/Sections/AboutUs/Aboutus';
//import Whyus from './components/Sections/Whyus/Whyus';
//import Footers from './components/Footer/Footers';
import Layout from './components/Layout/Layout';
import Home from './components/Home/Home';
import About from './components/About/About';
import Services from './components/Services/Services';
import Contact from './components/Contact/Contact';
import Gallery from './components/Gallery/Gallery';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import PortFolio from './components/PortFolio/PortFolio';

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
      {/* <Headers />
      <SliderComponent />
      <Aboutus />
      <Whyus />
      <Footers /> */}
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path='/services' element={<Services />} />
            <Route path='/gallery' element={<Gallery />} />
            <Route path="/contact-us" element={<Contact />} />
			<Route path='/PortFolio' element={<PortFolio />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
