function Aboutus() {
    return (
        <section className="bg-about-greenforest">
            <div className="container">
                <div className="row">
                    <div className="about-greenforest">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="about-greenforest-content">
                                    <h2>about Ecos Offset</h2>
                                    <p><span>Welcome to Ecos Offset Private Limited At Ecos Offset, we lead the way in forest and wildlife conservation consultancy, combining cutting-edge solutions with a commitment to sustainability. Explore how we can help drive positive environmental change.</span></p>
                                    <p>A premier consultancy dedicated to forest and wildlife conservation. We specialize in sustainable forestry management, environmental evaluation, and monitoring. Our innovative approaches integrate advanced solutions, fostering responsible industrial growth and promoting conscientious consumer behavior.</p>
                                    <a href="#" className="btn btn-default">call now</a>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="about-greenforest-img">
                                    <img src="assets/images/about-greenforet-img.jpg" alt="about-ecosgreen-img" className="img-responsive" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Aboutus;