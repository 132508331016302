import React from "react";
import Slider from "react-slick";

function SliderComponent() {
    const sliderData = [
        {
            path: 'assets/images/slider/',
            name: 'img-1.jpg'
        },
        {
            path: 'assets/images/slider/',
            name: 'img-2.jpg'
        },
        {
            path: 'assets/images/slider/',
            name: 'img-3.jpg'
        },
        {
            path: 'assets/images/slider/',
            name: 'img-4.jpg'
        },
        {
            path: 'assets/images/slider/',
            name: 'img-5.jpg'
        },
        {
            path: 'assets/images/slider/',
            name: 'img-6.jpg'
        },
        {
            path: 'assets/images/slider/',
            name: 'img-7.jpg'
        },
        {
            path: 'assets/images/slider/',
            name: 'img-8.jpg'
        },
        {
            path: 'assets/images/slider/',
            name: 'img-9.jpg'
        },
        {
            path: 'assets/images/slider/',
            name: 'img-10.jpg'
        }
    ];

    const sliderOptions = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        // speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        pauseOnHover: true,
       // arrows: true
    };

    return (
        <section className="bg-slider-option">
            <div className="slider-option slider-two">
                <div className="carousel-inner slider-container">
                    <Slider {...sliderOptions}>
                        {sliderData.map(item => {
                            return (
                                <div className="carousel-item" key={item.name}>
                                    <div className="slider-item" key={item.name}>
                                        <img src={item.path + item.name} alt="bg-slider-2" />
                                        <div className="slider-content-area">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-6"></div>
                                                    <div className="col-md-6">
                                                        <div className="slider-content">
                                                            <h3>welcome to Ecos Offset</h3>
                                                            <h2>save the world</h2>
                                                            <p>By Planting Tree Tomorrow!</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default SliderComponent;