// About.js
import { faHandPointRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function About() {
    return (
        <div className='about-page page-wrap bottomSpace'>
            <div className='container'>

            <h2><strong>About Us</strong></h2>
            <p><strong>Our Story</strong> Welcome to Ecos Offset Private Limited (Ecos Offset), a premier consultancy dedicated to forest and wildlife conservation. We specialize in sustainable forestry management, environmental evaluation, and monitoring. Our innovative approaches integrate advanced solutions, fostering responsible industrial growth and promoting conscientious consumer behavior.</p>
            <p><strong>Our Vision</strong> To pioneer transformative change in forest and wildlife conservation, sustainable forest management, and climate change mitigation, inspiring individuals and organizations to embrace sustainable practices.</p>
            <p><strong>Our Mission</strong> To provide unparalleled consultancy services in forest and wildlife conservation, empowering businesses, communities, and governments with innovative solutions for responsible stewardship of our planet's ecosystems.</p>
            <p><strong>Our Values</strong></p>
            <ul className='listHand'>
            <li><FontAwesomeIcon icon={faHandPointRight} size='1x' /> <strong>Sustainability</strong>: Promoting practices that preserve our planet for future generations.</li>
            <li><FontAwesomeIcon icon={faHandPointRight} size='1x' /> <strong>Innovation</strong>: Developing cutting-edge solutions to address environmental challenges.</li>
            <li><FontAwesomeIcon icon={faHandPointRight} size='1x' /> <strong>Integrity</strong>: Upholding ethical standards in all our work.</li>
            <li><FontAwesomeIcon icon={faHandPointRight} size='1x' /> <strong>Excellence</strong>: Delivering top-tier services and solutions.</li>
            <li><FontAwesomeIcon icon={faHandPointRight} size='1x' /> <strong>Collaboration</strong>: Leveraging collective expertise and resources.</li>
            <li><FontAwesomeIcon icon={faHandPointRight} size='1x' /> <strong>Responsibility</strong>: Minimizing our ecological footprint.</li>
            <li><FontAwesomeIcon icon={faHandPointRight} size='1x' /> <strong>Continuous Learning</strong>: Staying updated with advancements in environmental science.</li>
            <li><FontAwesomeIcon icon={faHandPointRight} size='1x' /> <strong>Client-Centricity</strong>: Prioritizing client needs with tailored solutions and exceptional service.</li>
            </ul>
            </div>
             <div className="moreAboutUs">
                <div className='container'>
                <h2>More <span className='textPrimary'>About Us</span></h2>
                <ul className='listHand'>
                    <li><FontAwesomeIcon icon={faHandPointRight} size='1x' /> With a seasoned team of cumulative experience across various verticals, ecoveda climate is uniquely positioned to tackle pressing climate challenges while creating sustainable opportunities for all stakeholders involved.</li>
                    <li><FontAwesomeIcon icon={faHandPointRight} size='1x' /> ecoveda Climate is a mission-driven organization dedicated to combating climate change and empowering vulnerable communities through sustainable solutions in the climate and carbon market sectors.</li>
                    <li><FontAwesomeIcon icon={faHandPointRight} size='1x' /> We strive to deliver environmentally sound,socially equitable, and economically scalable outcomes while simultaneously addressing climate challenges and uplifting underserved communities. Our mission is to drive a global transition to a low-carbon economy by advocating for collective action and leveraging carbon finance to create positive impacts on local communities and ecosystems. Guided by transparency,accountability, and integrity, we work towards achieving genuine and permanent emissions reductions while fostering sustainable development and resilience in parallelly impacted communities.</li>
                    <li><FontAwesomeIcon icon={faHandPointRight} size='1x' /> Our mission is clear: to pioneer sustainable solutions through innovation and expertise. Specializing in climate action, carbon markets, and sustainability consultancy, we are driven by a passion for global impact and collaboration. By leveraging transformative projects, we strive to make a tangible difference in the fight against climate change.</li>
                    <li><FontAwesomeIcon icon={faHandPointRight} size='1x' /> At ecovedaclimate, we believe that together, we can build a more sustainable and resilient world for generations to come.</li>
                </ul>
            </div>
            </div>
        </div>
    );
}

export default About;