import React from "react";
import { Gallery } from "react-grid-gallery";

function Gallerys() {
	const images = [
		{
			src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
			width: 320,
			height: 174,
			isSelected: true,
			caption: "After Rain (Jeshu John - designerspics.com)",
		},
		{
			src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
			width: 320,
			height: 212,
			tags: [
				{ value: "Ocean", title: "Ocean" },
				{ value: "People", title: "People" },
			],
			alt: "Boats (Jeshu John - designerspics.com)",
		},
		{
			src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
			width: 320,
			height: 212,
		},
	];


  return (
    <div className="gallery-page page-wrap">
      <div className="container">
        <h2>Gallery Page</h2>
        <Gallery images={images} />
      </div>
    </div>
  );
}

export default Gallerys;
