import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faPhone, faEnvelope, faGlobe } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";



function Footers(){
    return(
        <footer>
            <div className="bg-footer-top">
                <div className="container">
                    <div className="row">
                        <div className="footer-top">
                            <div className="row">
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="footer-widgets">
                                        <div className="widgets-title">
                                            <h3>About us</h3>
                                        </div>
                                        <div className="widgets-content">
                                            <p>We specialize in sustainable forestry management, environmental evaluation, and monitoring.</p>
                                        </div>
                                        <div className="address-box">
                                            <ul className="address">
                                                <li>
                                                    <FontAwesomeIcon icon={faHome} size="1x" style={{color: '#FFF'}} />
                                                    <span>New Chokoya Road, USA.</span>
                                                </li>
                                                <li>
                                                    <FontAwesomeIcon icon={faPhone} size="1x"  style={{color: '#FFF'}} />
                                                    <span>+8801 923 970 698.</span>
                                                </li>
                                                <li>
                                                    <FontAwesomeIcon icon={faEnvelope} className="mr-1" size="1x"  style={{color: '#FFF'}} />
                                                    <span>Contact@admin.com</span>

                                                </li>
                                                <li>
                                                    <FontAwesomeIcon icon={faGlobe} size="1x"  style={{color: '#FFF'}} />
                                                    <span>Emai@admin.com</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="footer-widgets">
                                        <div className="widgets-title">
                                            <h3>Latest News</h3>
                                        </div>
                                        <ul className="latest-news">
                                            <li>
                                                <span className="thumbnail-img">
												<img src="assets/images/home01/small-bog-img-1.jpg" alt="small-bog-img-1" className="img-responsive" />
											</span>
                                                <div className="thumbnail-content">
                                                    <h5><Link href="blog_single.html">Corem psum dolr them amectetuer adipiscing...</Link></h5>
                                                    <span className="post-date">04 February 2017</span>
                                                </div>
                                            </li>
                                            <li>
                                                <span className="thumbnail-img">
												<img src="assets/images/home01/small-bog-img-2.jpg" alt="small-bog-img-2" className="img-responsive" />
											</span>
                                                <div className="thumbnail-content">
                                                    <h5><a href="blog_single.html">Mirum est notare quam littera gothica nunc...</a></h5>
                                                    <span className="post-date">28 January 2017</span>
                                                </div>
                                            </li>
                                            <li>
                                                <span className="thumbnail-img">
												<img src="assets/images/home01/small-bog-img-3.jpg" alt="small-bog-img-3" className="img-responsive" />
											</span>
                                                <div className="thumbnail-content">
                                                    <h5><a href="blog_single.html">Corem psum dolr them amectetuer adipiscing...</a></h5>
                                                    <span className="post-date">03 January 2017</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="footer-widgets">
                                        <div className="widgets-title">
                                            <h3>Twitter Widget</h3>
                                        </div>
                                        <ul className="twitter-widget">
                                            <li>
                                                <div className="twitter-icon"><i className="fa fa-twitter" aria-hidden="true"></i></div>
                                                <div className="twitter-content">
                                                    <h5>Raritas etiam processus a theme dynamicus sequitur <a href="/">http://admin@gmail.com</a></h5>
                                                    <span className="post-date">03 January 2017</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="twitter-icon"><i className="fa fa-twitter" aria-hidden="true"></i></div>
                                                <div className="twitter-content">
                                                    <h5>Duis autem vel eum <a href="/">#iriure</a>dolor in hendrerit in vulputate </h5>
                                                    <span className="post-date">8 months ago</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="twitter-icon"><i className="fa fa-twitter" aria-hidden="true"></i></div>
                                                <div className="twitter-content">
                                                    <h5><a href="/">@frankdoe</a> am liber tempor cum soluta nobis eleifend</h5>
                                                    <span className="post-date">03 January 2017</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12">
                                    <div className="footer-widgets">
                                        {/*<div className="widgets-title">
                                            <h3>Get In Touch</h3>
                                        </div>
                                        <div className="footer-instagram">
                                            <div>
                                                <a href="/">Phone Number</a>
                                            </div>
                                            <div>
                                                <a href="/">Phone Number</a>
                                            </div>
                                            <div>
                                                <a href="/">Phone Number</a>
                                            </div>
                                             <a href="#"><img src="assets/images/home01/footer-instagram-img-1.jpg" alt="footer-instagram-img-1"></a>
                                            <a href="#"><img src="assets/images/home01/footer-instagram-img-2.jpg" alt="footer-instagram-img-2"></a>
                                            <a href="#"><img src="assets/images/home01/footer-instagram-img-3.jpg" alt="footer-instagram-img-3"></a>
                                            <a href="#"><img src="assets/images/home01/footer-instagram-img-4.jpg" alt="footer-instagram-img-4"></a>
                                            <a href="#"><img src="assets/images/home01/footer-instagram-img-5.jpg" alt="footer-instagram-img-5"></a>
                                            <a href="#"><img src="assets/images/home01/footer-instagram-img-6.jpg" alt="footer-instagram-img-6"></a>
                                            <a href="#"><img src="assets/images/home01/footer-instagram-img-7.jpg" alt="footer-instagram-img-7"></a>
                                            <a href="#"><img src="assets/images/home01/footer-instagram-img-8.jpg" alt="footer-instagram-img-8"></a>
                                            <a href="#"><img src="assets/images/home01/footer-instagram-img-9.jpg" alt="footer-instagram-img-9"></a> 
                                        </div>*/}
                                        
                                    </div>
                                    <div className="footer-widgets">
                                        <div className="widgets-title">
                                            <h3>Customer Services</h3>
                                        </div>
                                        <div className="static-links">
                                            <div>
                                                <Link className="staticLinks" to="/privacy-policy">Privacy Policy</Link>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="footer-bottom">
                            <div className="copyright-txt">
                                <p>© Copyright 2024 & Design By<Link href="/" title="Al-Amin(Web Designer)">&nbsp; &nbsp;Ecos Offset Private Limited</Link></p>
                            </div>
                            <div className="social-box">
                                <ul className="social-icon-rounded">
                                    <li><a href="/"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"/></svg></a></li>
                                    <li><a href="/">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z"/></svg>
                                        </a></li>
                                    <li><a href="/">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512"><path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"/></svg>
                                        </a></li>
                                    <li><a href="/">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M403.2 32H44.8C20.1 32 0 52.1 0 76.8v358.4C0 459.9 20.1 480 44.8 480h358.4c24.7 0 44.8-20.1 44.8-44.8V76.8c0-24.7-20.1-44.8-44.8-44.8zM377 180.8c-1.4 31.5-23.4 74.7-66 129.4-44 57.2-81.3 85.8-111.7 85.8-18.9 0-34.8-17.4-47.9-52.3-25.5-93.3-36.4-148-57.4-148-2.4 0-10.9 5.1-25.4 15.2l-15.2-19.6c37.3-32.8 72.9-69.2 95.2-71.2 25.2-2.4 40.7 14.8 46.5 51.7 20.7 131.2 29.9 151 67.6 91.6 13.5-21.4 20.8-37.7 21.8-48.9 3.5-33.2-25.9-30.9-45.8-22.4 15.9-52.1 46.3-77.4 91.2-76 33.3 .9 49 22.5 47.1 64.7z"/></svg>
                                        </a></li>
                                    <li><a href="/">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3 .8-3.4 5-20.3 6.9-28.1 .6-2.5 .3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z"/></svg>
                                        </a></li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footers;