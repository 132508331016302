import React from 'react';
import { Outlet } from 'react-router-dom';
import Footers from '../Footer/Footers';
import Headers from '../Headers/Headers';

function Layout() {
    return (
        <div className='ecos-offset'>
            <Headers />
            <main>
                <Outlet />
            </main>
            <Footers />
        </div>
    );
}

export default Layout;