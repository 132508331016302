import React from 'react'
import './PortFolio.css';

const PortFolio = () => {
  return (
    <>
      <div className='page-wrap'>
        <div className='container'>
            <h2 className='barHead'>Start Planning Your Career as an Environmental Scientist Today</h2>
            <p>EnvironmentalScience.org is the ultimate guide to everything you need to know about starting your career as an Environmental Scientist. We offer information on all aspects of becoming and working as an Environmental Scientist, including:</p>
            <ul className='circleStyle'>
                <li>Average Environmental Science Salaries for different professions</li>
                <li>Common Work Environments</li>
                <li>Completing Education Requirements</li>
                <li>Finding the Right College Program</li>
                <li>Environmental Scientist Job Possibilities</li>
            </ul>
            <p>Environmental Scientists have rewarding, challenging, and well-paying careers that offer excellent opportunities for growth and advancement. With the resources provided by EnvironmentalScience.org, you can begin your journey towards earning a degree in Environmental Science and following the career of your dreams.</p>
            <h2 className='barHead'>What is an Environmental Scientist?</h2>
            <p>Environmental Scientists are those who work in a variety of fields related to the Earth and its resources. They may work in a variety of roles from Environmental Lawyer, Coral Reef Sanctuary Manager, Antarctic Researcher, Laboratory Technician, Wastewater Technician, or Solar Panel Engineer. They have gone through school in a variety of ways to obtain their current positions.</p>
            <p>Environmental science, studies, policy, and management degree programs are offered at many colleges and universities throughout the world, including quite a few in the United States and Canada. Many students may have begun in a different major, and gone on to graduate school for an Environmental degree. It is one of the fastest growing fields in employment in the United States according the US Labor Bureau.</p>
            <h2 className='barHead'>What does an Environmental Scientist Do?</h2>
            <p>Environmental Scientists work with the Earth's Resources. That is a very broad definition, but considering an Environmental Scientist could be doing anything from helping increase palm wood production in the forests of Samoa to sampling frozen lakes in Siberia or arguing legal cases before the Supreme Court, Environmental Scientists do a lot! Most communities employ at least a few at their local sanitation station, in their building/roads department, and in their public health departments. That would be considered a minimum for a very small community. Cities hire entire environmental legal teams, employ water and transportation teams, infrastructure specialists, local environmental specialists, parks and recreation, etc. The US government is a huge employer; the United States Environmental Protection Agency, the National Park Service, the National Oceanographic and Atmospheric Administration, the United States Geological Survey, and the Department of Energy are just a few of the major employers at the federal level. The private sector also hires a lot of Environmental Science professionals. They may work in as college professors, in R&D, as lobbyists, as remediation specialists, journalists, etc. Here are some potential jobs/careers:</p>
            <ul className='circleStyle'>
                <li>Agricultural Engineer</li>
                <li>College Professor</li>
                <li>Park Ranger</li>
                <li>Oceanographer</li>
                <li>Toxicologist</li>
            </ul>
        </div>
        </div>  
    </>
  )
}

export default PortFolio
