import Aboutus from "../Sections/AboutUs/Aboutus";
import SliderComponent from "../Sections/SliderSections/SliderComponent";
import Whyus from "../Sections/Whyus/Whyus";

function Home() {
    return (
        <div className="home-page">
            <SliderComponent />
            <Aboutus />
            <Whyus />
        </div>
    );
}

export default Home;