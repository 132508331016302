import { faLink, faSearchPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Whyus() {
    return (
        <section className="bg-recent-project">
            <div className="container">
                <div className="row">
                    <div className="recent-project">
                        <div className="section-header">
                            <h2>Why choose us</h2>
                            <p>Our Mission To provide unparalleled consultancy services in forest and wildlife conservation, empowering businesses, communities, and governments with innovative solutions for responsible stewardship of our planet's ecosystems.</p>
                        </div>

                        <div id="filters" className="button-group ">
                            <button className="button is-checked" data-filter="*">show all</button>
                            <button className="button" data-filter=".cat-1">environment</button>
                            <button className="button" data-filter=".cat-2">recycling</button>
                            <button className="button" data-filter=".cat-3">ecology</button>
                            <button className="button" data-filter=".cat-4">climate</button>
                        </div>
                        <div className="portfolio-items">
                            <div className="item cat-1" data-category="transition">
                                <div className="item-inner">
                                    <div className="portfolio-img">
                                        <div className="overlay-project"></div>
                                        <img src="assets/images/recent-project-img-1.jpg" alt="recent-project-img-1" />
                                        <ul className="project-link-option">
                                            <li className="project-link"><a href="project_single.html">
                                                <FontAwesomeIcon icon={faLink} size="1x" /></a></li>
                                            <li className="project-search"><a href="assets/images/recent-project-img-1.jpg" data-rel="lightcase:myCollection"><FontAwesomeIcon icon={faSearchPlus} size="1x" />
                                            </a></li>
                                        </ul>
                                    </div>
                                    <div className="recent-project-content">
                                        <h4><a href="project_single.html">Sustainability</a></h4>
                                        <p>By : <span><a href="#">Ecos Offset</a></span></p>
                                    </div>
                                </div>
                            </div>

                            <div className="item cat-2 " data-category="metalloid">
                                <div className="item-inner">
                                    <div className="portfolio-img">
                                        <div className="overlay-project"></div>
                                        <img src="assets/images/recent-project-img-2.jpg" alt="recent-project-img-2" />
                                        <ul className="project-link-option">
                                            <li className="project-link"><a href="project_single.html"><FontAwesomeIcon icon={faLink} size="1x" /></a></li>
                                            <li className="project-search"><a href="assets/images/recent-project-img-2.jpg" data-rel="lightcase:myCollection"><FontAwesomeIcon icon={faSearchPlus} size="1x" /></a></li>
                                        </ul>
                                    </div>
                                    <div className="recent-project-content">
                                        <h4><a href="project_single.html">Helping Young Planting</a></h4>
                                        <p>By : <span><a href="#">Ecos Offset</a></span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="item cat-3 " data-category="post-transition">
                                <div className="item-inner">
                                    <div className="portfolio-img">
                                        <div className="overlay-project"></div>
                                        <img src="assets/images/recent-project-img-3.jpg" alt="recent-project-img-3" />
                                        <ul className="project-link-option">
                                            <li className="project-link"><a href="project_single.html"><FontAwesomeIcon icon={faLink} size="1x" /></a></li>
                                            <li className="project-search"><a href="assets/images/recent-project-img-3.jpg" data-rel="lightcase:myCollection"><FontAwesomeIcon icon={faSearchPlus} size="1x" /></a></li>
                                        </ul>
                                    </div>
                                    <div className="recent-project-content">
                                        <h4><a href="project_single.html">Need Solar Panels</a></h4>
                                        <p>By : <span><a href="#">Ecos Offset</a></span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="item cat-2" data-category="post-transition">
                                <div className="item-inner">
                                    <div className="portfolio-img">
                                        <div className="overlay-project"></div>
                                        <img src="assets/images/recent-project-img-4.jpg" alt="recent-project-img-4" />
                                        <ul className="project-link-option">
                                            <li className="project-link"><a href="project_single.html"><FontAwesomeIcon icon={faLink} size="1x" /></a></li>
                                            <li className="project-search"><a href="assets/images/recent-project-img-4.jpg" data-rel="lightcase:myCollection"><FontAwesomeIcon icon={faSearchPlus} size="1x" /></a></li>
                                        </ul>
                                    </div>
                                    <div className="recent-project-content">
                                        <h4><a href="project_single.html">Save The Ozone Layer</a></h4>
                                        <p>By : <span><a href="#">Ecos Offset</a></span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="item cat-4" data-category="transition">
                                <div className="item-inner">
                                    <div className="portfolio-img">
                                        <div className="overlay-project"></div>
                                        <img src="assets/images/recent-project-img-5.jpg" alt="recent-project-img-5" />
                                        <ul className="project-link-option">
                                            <li className="project-link"><a href="project_single.html"><FontAwesomeIcon icon={faLink} size="1x" /></a></li>
                                            <li className="project-search"><a href="assets/images/recent-project-img-5.jpg" data-rel="lightcase:myCollection"><FontAwesomeIcon icon={faSearchPlus} size="1x" /></a></li>
                                        </ul>
                                    </div>
                                    <div className="recent-project-content">
                                        <h4><a href="project_single.html">Save Water From Polution</a></h4>
                                        <p>By : <span><a href="#">Ecos Offset</a></span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="item cat-1" data-category="alkali">
                                <div className="item-inner">
                                    <div className="portfolio-img">
                                        <div className="overlay-project"></div>
                                        <img src="assets/images/recent-project-img-6.jpg" alt="recent-project-img-6" />
                                        <ul className="project-link-option">
                                            <li className="project-link"><a href="project_single.html"><FontAwesomeIcon icon={faLink} size="1x" /></a></li>
                                            <li className="project-search"><a href="assets/images/recent-project-img-6.jpg" data-rel="lightcase:myCollection"><FontAwesomeIcon icon={faSearchPlus} size="1x" /></a></li>
                                        </ul>
                                    </div>
                                    <div className="recent-project-content">
                                        <h4><a href="project_single.html">Make Plants Alive</a></h4>
                                        <p>By : <span><a href="#">Ecos Offset</a></span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Whyus;